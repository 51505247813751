import LogoutIcon from '@mui/icons-material/Logout'
import {
  AppBar,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography
} from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TAppDispatch } from '~/src/Configurations/AppStore'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'
import { getIsLoggedInSelector } from '~/src/Redux/Auth/Selectors'
import logoutService from '~/src/Redux/Auth/Services/logout.Service'

const Header: React.FC<IWithRouterProps> = props => {
  const dispatch = useDispatch<TAppDispatch>()
  const isLoggedIn = useSelector(getIsLoggedInSelector)

  const handleLogout = () => {
    dispatch(logoutService())
    props.navigateToRoute(APP_ROUTES.LOGIN)
  }

  return (
    <AppBar position='static'>
      <Toolbar
        component={Stack}
        direction='row'
        spacing={2}
        sx={{ py: { xs: 1, md: 2 }, justifyContent: 'space-between' }}
      >
        <Typography variant='h6'>Belgium Mobile Antenna Permits</Typography>
        {isLoggedIn && (
          <>
            <Button
              variant='outlined'
              startIcon={<LogoutIcon />}
              onClick={handleLogout}
              sx={{
                color: '#fff',
                borderColor: '#fff',
                display: { xs: 'none', md: 'flex' }
              }}
            >
              Logout
            </Button>
            <IconButton
              onClick={handleLogout}
              sx={{
                color: '#fff',
                border: '1px solid #fff',
                borderRadius: '4px',
                display: { xs: 'flex', md: 'none' }
              }}
            >
              <LogoutIcon />
            </IconButton>
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(Header)
