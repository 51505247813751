import { Stack } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Components/Header'

const UnauthLayout: React.FC = () => {
  return (
    <Stack
      direction='column'
      sx={{
        height: 'calc(100 * var(--vh))', // TODO: Solve for web and cordova
        overflow: 'auto'
      }}
    >
      <Header />
      <Outlet />
    </Stack>
  )
}

export default UnauthLayout
