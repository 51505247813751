import { createSelector } from '@reduxjs/toolkit'

import { TAppStore } from '~/src/Configurations/AppStore'
import { TTransmitter } from '~/src/Entities/Transmitter/Transmitter.Types'
import { _reduce } from '~/src/Utils/lodash'

export const SLICE_NAME = 'transmitter'

const select = (state: TAppStore) => state[SLICE_NAME]

export const getTransmitterReducer = select

export const getTransmittersCountSelector = createSelector(
  select,
  reducer => reducer.transmittersCount
)

export const getTransmittersIndexSelector = createSelector(
  select,
  reducer => reducer.transmittersIndex
)

export const getTransmittersSelector = createSelector(select, reducer => {
  const transmitters = _reduce(
    Object.values(reducer.transmittersIndex),
    (acc, transmitters) => [...acc, ...transmitters],
    [] as TTransmitter[]
  )
  return transmitters
})
