import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'

import { _reduce } from '~/src/Utils/lodash'

import { TSiteIndex } from '~/src/Entities/Site/Site.Types'
import { flushAllAction } from '../Actions'
import { fetchTransmittersTraceActions } from '../Transmitter/Actions'
import { getSitesTraceActions } from './Actions'
import { getSiteDerivedData } from './DataProcessors'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, T_SITE_REDUCER } from './Types'

const sliceOptions: CreateSliceOptions<T_SITE_REDUCER> = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<T_SITE_REDUCER>): void => {
    builder.addCase(getSitesTraceActions.success, (state, { payload }) => {
      const { sitesIndex, asOn } = payload

      state.asOn = asOn
      state.sitesIndex = sitesIndex
    })

    builder.addCase(
      fetchTransmittersTraceActions.success,
      (state, { payload }) => {
        state.sitesIndex = _reduce(
          Object.values(state.sitesIndex),
          (acc, site) => {
            if (payload.errorSites[site.id]) {
              return acc
            }

            const transmitters = payload.transmittersIndex[site.id]
            if (transmitters) {
              const derivedData = getSiteDerivedData(transmitters)
              acc[site.id] = {
                ...site,
                transmittersFetched: true,
                ...derivedData
              }
            } else {
              acc[site.id] = { ...site }
            }

            return acc
          },
          {} as TSiteIndex
        )
      }
    )

    builder.addCase(flushAllAction, () => ({ ...INITIAL_STATE }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
