import { NavigateOptions, To } from 'react-router-dom'

export type AppRouteItem = {
  pathname: To
  options?: NavigateOptions
}

const APP_ROUTES = {
  LOGIN: {
    pathname: '/auth/login'
  } as AppRouteItem,

  DASHBOARD: {
    pathname: '/dashboard'
  } as AppRouteItem,

  SITE_LIST: {
    pathname: '/sites'
  } as AppRouteItem,

  TRANSMITTER_LIST: {
    pathname: '/transmitters'
  } as AppRouteItem,

  REPORTS: {
    pathname: '/reports'
  } as AppRouteItem,

  ANY: {
    pathname: '*'
  } as AppRouteItem,

  DEFAULT_UNAUTH_FALLBACK: {
    pathname: '/auth/login'
  } as AppRouteItem,

  DEFAULT_AUTH_FALLBACK: {
    pathname: '/dashboard'
  } as AppRouteItem
}

export default APP_ROUTES
