import { createSelector } from '@reduxjs/toolkit'

import { TAppStore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'processor'

const select = (state: TAppStore) => state[SLICE_NAME]

export const getProcessorReducer = select

export const getFetchTransmittersProcessSelector = createSelector(
  getProcessorReducer,
  processorReducer => processorReducer.fetchTransmitters
)
