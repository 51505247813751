import { TTransmittersIndex } from '~/src/Entities/Transmitter/Transmitter.Types'

export interface T_TRANSMITTER_REDUCER {
  transmittersCount: number
  transmittersIndex: TTransmittersIndex
}

export const INITIAL_STATE: T_TRANSMITTER_REDUCER = {
  transmittersCount: 0,
  transmittersIndex: {}
}
