import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'

import { loginTraceActions, logoutTraceActions } from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, T_AUTH_REDUCER } from './TYPES'

const sliceOptions: CreateSliceOptions<T_AUTH_REDUCER> = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<T_AUTH_REDUCER>): void => {
    builder.addCase(loginTraceActions.success, state => {
      state.isLoggedIn = true
    })

    builder.addCase(logoutTraceActions.success, state => {
      state.isLoggedIn = false
    })
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
