import { TSite, TSiteIndex } from '~/src/Entities/Site/Site.Types'
import { TTransmitter } from '~/src/Entities/Transmitter/Transmitter.Types'
import { _difference, _reduce, _sortBy } from '~/src/Utils/lodash'
import { sanitizeString } from '~/src/Utils/string'

export function buildSitesIndex(currentIndex: TSiteIndex, data: any) {
  const sitesIndex: TSiteIndex = {}
  const { features = [] } = data || {}

  features.forEach((feature: any) => {
    const site: TSite = extractSite(feature)
    const existingSite = currentIndex[site.id]
    const prevSite = sitesIndex[site.id]

    if (!site.id) {
      return
    }

    if (!existingSite && !prevSite) {
      sitesIndex[site.id] = site
    } else if (!existingSite && prevSite) {
      sitesIndex[site.id] =
        prevSite.approvalDateEpoch >= site.approvalDateEpoch ? prevSite : site
    } else if (existingSite && !prevSite) {
      sitesIndex[site.id] =
        existingSite.approvalDateEpoch >= site.approvalDateEpoch
          ? existingSite
          : site
    } else if (existingSite && prevSite) {
      const prioritySite =
        existingSite.approvalDateEpoch >= prevSite.approvalDateEpoch
          ? existingSite
          : prevSite

      sitesIndex[site.id] =
        prioritySite.approvalDateEpoch >= site.approvalDateEpoch
          ? prioritySite
          : site
    }
  })

  const newSites = _reduce(
    Object.values(sitesIndex),
    (acc: string[], site: TSite) => {
      if (!site.transmittersFetched) {
        acc.push(site.id)
      }
      return acc
    },
    []
  )

  const removedSites = _difference(
    Object.keys(currentIndex),
    Object.keys(sitesIndex)
  )

  return { sitesIndex, newSites, removedSites }
}

function extractSite(feature: any): TSite {
  const { geometry = {}, properties = {} } = feature
  const [coordX, coordY]: number[] = geometry.coordinates || []

  const addressParts = getAddressParts(properties.locatiebeschrijving)
  const address = sanitizeString(addressParts.address)
  const commune = sanitizeString(addressParts.commune)
  const postalCode = sanitizeString(addressParts.postalCode)

  const dossierNummer = sanitizeString(properties.dossiernummer || '')
  const reference = sanitizeString(properties.referentie || '')
  const siteCode = sanitizeString(properties.sitecode || '')

  const approvalDate = sanitizeString(properties.goedkeuringsdatum || '')
  const approvalDateObj = new Date(approvalDate)
  const approvalDateEpoch = approvalDateObj ? approvalDateObj.getTime() : 0
  const approvalYYMM = approvalDateObj
    ? approvalDateObj.getFullYear() * 100 + approvalDateObj.getMonth() + 1
    : 0

  const operatorName = sanitizeString(properties.operatornaam || '')
  const docLink = sanitizeString(properties.conformiteitsattest || '')

  const site = {
    id: siteCode,
    region: 'Flanders',
    address,
    commune,
    postalCode,
    coordX,
    coordY,
    dossierNummer,
    reference,
    siteCode,
    approvalDate,
    approvalDateEpoch,
    approvalYYMM,
    operatorName,
    docLink,
    transmittersFetched: false,
    configuration: [],
    technologies: [],
    minHeight: 0,
    maxHeight: 0
  }

  return site
}

function getAddressParts(addressString = '') {
  const addressParts = (addressString || '').split(',')
  const address: string = addressParts[0] || ''

  let [postalCode = '', commune = '']: string[] = (addressParts[1] || '')
    .trim()
    .split(' ')

  if (postalCode && `${Number(postalCode)}` !== postalCode) {
    commune = postalCode
    postalCode = ''
  }

  return { address, commune, postalCode }
}

export function getSiteDerivedData(
  transmitters: TTransmitter[]
): Partial<TSite> {
  const { configuration, technologies, minHeight, maxHeight } =
    transmitters.reduce(
      (acc, transmitter) => {
        acc.configuration.add(transmitter.band)
        acc.technologies.add(transmitter.technology)
        acc.minHeight =
          acc.minHeight === null
            ? transmitter.height
            : Math.min(acc.minHeight, transmitter.height)
        acc.maxHeight =
          acc.maxHeight === null
            ? transmitter.height
            : Math.max(acc.maxHeight, transmitter.height)
        return acc
      },
      {
        configuration: new Set(),
        technologies: new Set(),
        minHeight: null as number | null,
        maxHeight: null as number | null
      }
    )

  return {
    configuration: _sortBy(Array.from(configuration)) as number[],
    technologies: _sortBy(Array.from(technologies)) as string[],
    minHeight: minHeight || 0,
    maxHeight: maxHeight || 0
  }
}
