import { TSiteIndex } from '~/src/Entities/Site/Site.Types'

export interface T_SITE_REDUCER {
  asOn: string
  sitesIndex: TSiteIndex
}

export const INITIAL_STATE: T_SITE_REDUCER = {
  asOn: '',
  sitesIndex: {}
}
