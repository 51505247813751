import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { TTransmittersIndex } from '~/src/Entities/Transmitter/Transmitter.Types'
import { _reduce } from '~/src/Utils/lodash'
import { flushAllAction } from '../Actions'
import { getSitesTraceActions } from '../Site/Actions'
import { fetchTransmittersTraceActions } from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, T_TRANSMITTER_REDUCER } from './Types'

const sliceOptions: CreateSliceOptions<T_TRANSMITTER_REDUCER> = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<T_TRANSMITTER_REDUCER>
  ): void => {
    builder.addCase(getSitesTraceActions.success, (state, { payload }) => {
      const { removedSites } = payload

      state.transmittersIndex = Object.keys(state.transmittersIndex).reduce(
        (acc, siteId) => {
          if (!removedSites.includes(siteId)) {
            acc[siteId] = state.transmittersIndex[siteId]
          }
          return acc
        },
        {} as TTransmittersIndex
      )
    })

    builder.addCase(
      fetchTransmittersTraceActions.success,
      (state, { payload }) => {
        const { transmittersIndex } = payload

        const updatedTransmittersIndex = {
          ...state.transmittersIndex,
          ...transmittersIndex
        }

        state.transmittersCount = _reduce(
          updatedTransmittersIndex,
          (acc, transmitters) => acc + transmitters.length,
          0
        )

        state.transmittersIndex = updatedTransmittersIndex
      }
    )

    builder.addCase(flushAllAction, () => ({ ...INITIAL_STATE }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
