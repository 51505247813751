import { ReducersMapObject, combineReducers } from 'redux'

import AuthReducer from './Auth/Reducer'
import { SLICE_NAME as AuthSliceName } from './Auth/Selectors'
import { T_AUTH_REDUCER } from './Auth/TYPES'

import ServiceTrackerReducer from './ServiceTracker/Reducer'
import { SLICE_NAME as ServiceTrackerSliceName } from './ServiceTracker/Selectors'
import { T_SERVICE_TRACKER_REDUCER } from './ServiceTracker/TYPES'

import SiteReducer from './Site/Reducer'
import { SLICE_NAME as SiteSliceName } from './Site/Selectors'
import { T_SITE_REDUCER } from './Site/Types'

import TransmitterReducer from './Transmitter/Reducer'
import { SLICE_NAME as TransmitterSliceName } from './Transmitter/Selectors'
import { T_TRANSMITTER_REDUCER } from './Transmitter/Types'

import ProcessorReducer from './Processor/Reducer'
import { SLICE_NAME as ProcessorSliceName } from './Processor/Selectors'
import { T_PROCESSOR_REDUCER } from './Processor/Types'

export type TReducers = {
  [AuthSliceName]: T_AUTH_REDUCER
  [ServiceTrackerSliceName]: T_SERVICE_TRACKER_REDUCER
  [SiteSliceName]: T_SITE_REDUCER
  [TransmitterSliceName]: T_TRANSMITTER_REDUCER
  [ProcessorSliceName]: T_PROCESSOR_REDUCER
}

const reducers: ReducersMapObject<TReducers> = {
  [AuthSliceName]: AuthReducer,
  [ServiceTrackerSliceName]: ServiceTrackerReducer,
  [SiteSliceName]: SiteReducer,
  [TransmitterSliceName]: TransmitterReducer,
  [ProcessorSliceName]: ProcessorReducer
}

export default combineReducers<ReducersMapObject<TReducers>>(reducers)

export const persistedReducers: (keyof TReducers)[] = [
  AuthSliceName,
  SiteSliceName,
  TransmitterSliceName
]
