import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import {
  disableFetchTransmittersAction,
  enableFetchTransmittersAction,
  toggleFetchTransmittersAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, T_PROCESSOR_REDUCER } from './Types'

const sliceOptions: CreateSliceOptions<T_PROCESSOR_REDUCER> = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<T_PROCESSOR_REDUCER>
  ): void => {
    builder.addCase(enableFetchTransmittersAction, state => {
      state.fetchTransmitters = true
    })
    builder.addCase(disableFetchTransmittersAction, state => {
      state.fetchTransmitters = false
    })
    builder.addCase(toggleFetchTransmittersAction, state => {
      state.fetchTransmitters = !state.fetchTransmitters
    })
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
