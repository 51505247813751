import { CssBaseline, ThemeProvider } from '@mui/material'
import type React from 'react'

import AppInitializer from './AppInitializer'
import AppTheme from './AppTheme'

import { THEME_MODE_STORAGE_KEY } from '~/src/Constants/THEME'

import './App.scss'
import { useEffect } from 'react'

export interface IAppProps {
  persisted: boolean
}

const App: React.FC<IAppProps> = props => {
  const { persisted } = props

  useEffect(() => {
    window.addEventListener('resize', () => {
      setVHVariable()
    })

    setVHVariable()

    function setVHVariable() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }, [])

  return (
    <ThemeProvider theme={AppTheme} modeStorageKey={THEME_MODE_STORAGE_KEY}>
      <CssBaseline>{persisted && <AppInitializer />}</CssBaseline>
    </ThemeProvider>
  )
}

export default App
