import traceActionsCreator from '../traceActionsCreator'
import { IGetSitesServiceResponse } from './ServiceTypes/getSites.ServiceTypes'

export const getSitesServiceName = 'Site/getSites'
export const getSitesTraceActions =
  traceActionsCreator<IGetSitesServiceResponse>(getSitesServiceName)

export const downloadBrusselsSitesServiceName = 'Site/downloadBrusselsSites'
export const downloadBrusselsSitesTraceActions = traceActionsCreator(
  downloadBrusselsSitesServiceName
)

export const downloadWallonieSitesServiceName = 'Site/downloadWallonieSites'
export const downloadWallonieSitesTraceActions = traceActionsCreator(
  downloadWallonieSitesServiceName
)
