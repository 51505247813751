import { Backdrop, CircularProgress } from '@mui/material'
import type React from 'react'

const Loader: React.FC = () => {
  return (
    <Backdrop open>
      <CircularProgress />
    </Backdrop>
  )
}

export default Loader
