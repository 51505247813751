import { createAction } from '@reduxjs/toolkit'

export const enableFetchTransmittersAction = createAction(
  'Processor/enableFetchTransmitters'
)

export const disableFetchTransmittersAction = createAction(
  'Processor/disableFetchTransmitters'
)

export const toggleFetchTransmittersAction = createAction(
  'Processor/toggleFetchTransmitters'
)
