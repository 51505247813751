import { createSelector } from '@reduxjs/toolkit'

import { TAppStore } from '~/src/Configurations/AppStore'
import { _filter, _map, _size, _take } from '~/src/Utils/lodash'

export const SLICE_NAME = 'site'

const select = (state: TAppStore) => state[SLICE_NAME]

export const getSiteReducer = select

export const getSitesIndexSelector = createSelector(
  select,
  reducer => reducer.sitesIndex
)

export const getSitesSelector = createSelector(select, reducer =>
  Object.values(reducer.sitesIndex)
)

export const getSiteAsOnSelector = createSelector(
  select,
  reducer => reducer.asOn
)

export const getSitesCountSelector = createSelector(select, reducer =>
  _size(reducer.sitesIndex)
)

export const getSitesProcessedCountSelector = createSelector(select, reducer =>
  _size(_filter(reducer.sitesIndex, 'transmittersFetched'))
)

export const getSitesToProcessSelector = createSelector(
  (state: TAppStore) => state[SLICE_NAME].sitesIndex,
  sitesIndex => {
    let sites = Object.values(sitesIndex)
    sites = _filter(sites, site => !site.transmittersFetched)
    sites = _take(sites, 5)
    const siteMeta = _map(sites, site => ({
      siteId: site.id,
      operatorName: site.operatorName,
      region: site.region,
      dossierNummer: site.dossierNummer,
      approvalDateEpoch: site.approvalDateEpoch
    }))
    return siteMeta
  }
)
