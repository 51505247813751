import React from 'react'
import { RouteObject, createBrowserRouter } from 'react-router-dom'

import AuthLayout from '../Layouts/Auth.Layout'
import AuthLayoutLoader from '../Layouts/Auth.Layout/Auth.Layout.Loader'
import UnauthLayout from '../Layouts/Unauth.Layout'
import UnauthLayoutLoader from '../Layouts/Unauth.Layout/Unauth.Layout.Loader'

import ErrorBoundary from '../Layouts/ErrorBoundary'
import NotFoundLoader from '../Pages/NotFound/NotFound.Loader'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'

// import LoginPage from '~/src/Pages/Login/Login.Page'
// import DashboardPage from '~/src/Pages/Dashboard/Dashboard.Page'
// import SiteListPage from '~/src/Pages/SiteList/SiteList.Page'
// import TransmitterListPage from '~/src/Pages/TransmitterList/TransmitterList.Page'
// import ReportsPage from '~/src/Pages/Reports/Reports.Page'
// import NotFoundPage from '~/src/Pages/NotFound/NotFound.Page'

const LoginPage = React.lazy(
  () => import(/* webpackChunkName: "Login" */ '~/src/Pages/Login/Login.Page')
)

const DashboardPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Dashboard" */ '~/src/Pages/Dashboard/Dashboard.Page'
    )
)

const SiteListPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SiteList" */ '~/src/Pages/SiteList/SiteList.Page'
    )
)

const TransmitterListPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TransmitterList" */ '~/src/Pages/TransmitterList/TransmitterList.Page'
    )
)

const ReportsPage = React.lazy(
  () =>
    import(/* webpackChunkName: "Reports" */ '~/src/Pages/Reports/Reports.Page')
)

const NotFoundPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "NotFound" */ '~/src/Pages/NotFound/NotFound.Page'
    )
)

const routeObj: RouteObject[] = [
  {
    element: <UnauthLayout />,
    loader: UnauthLayoutLoader,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.LOGIN.pathname,
        element: <LoginPage />
      } as RouteObject
    ]
  },
  {
    element: <AuthLayout />,
    loader: AuthLayoutLoader,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.DASHBOARD.pathname,
        element: <DashboardPage />
      } as RouteObject,
      {
        path: APP_ROUTES.SITE_LIST.pathname,
        element: <SiteListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.TRANSMITTER_LIST.pathname,
        element: <TransmitterListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.REPORTS.pathname,
        element: <ReportsPage />
      } as RouteObject
    ]
  },

  {
    path: APP_ROUTES.ANY.pathname,
    loader: NotFoundLoader,
    errorElement: <ErrorBoundary />,
    element: <NotFoundPage />
  } as RouteObject
]

const getAppRouter = () =>
  createBrowserRouter(routeObj, {
    future: {
      v7_fetcherPersist: true,
      v7_relativeSplatPath: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true
    }
  })

export default getAppRouter
