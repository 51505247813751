import { extendTheme } from '@mui/material'

import '@fontsource-variable/inter/opsz.css'

const AppTheme = extendTheme({
  typography: {
    fontFamily: 'Inter Variable, Helvetica'
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          'input:-webkit-autofill': {
            boxShadow: 'inherit',
            textFillColor: 'inherit'
          }
        }
      }
    }
  }
})

export default AppTheme
